<template>
  <div class="position-relative">

    <apexchart 
      type="line" 
      :options="mainChartOptions" 
      :series="processedSeries" 
      height="100%" 
      width="100%"
    ></apexchart>
  </div>
  </template>
  
  <script>
  // Services
  import apexchart from 'vue-apexcharts'
  
  export default {
  name: 'PlayoutMasterChart',
  props: {
    masterData: {
      type: Object,
      required: true
    },
    selectedView: {
      type: String,
      required: false,
      default: 'daily'
    },
    selectedMetric: {
      type: String,
      required: false,
      default: 'combined'
    }
  },
  data: () => ({
  }),
  components: {
    apexchart
  },
  computed: {
    filteredSeries() {
      if (!this.masterData.series) return [];
      
      // First filter by view type (daily/cumulative)
      let filteredByView = [];
      switch (this.selectedView) {
        case 'daily':
          filteredByView = this.masterData.series.filter(series => series.name.includes('Daily'));
          break;
        case 'cumulative':
          filteredByView = this.masterData.series.filter(series => series.name.includes('Cumulative'));
          break;
        default:
          filteredByView = this.masterData.series;
      }
  
      // Then filter by metric type (plays/impacts)
      switch (this.selectedMetric) {
        case 'plays':
          return filteredByView.filter(series => series.name.includes('Plays'));
        case 'impacts':
          return filteredByView.filter(series => series.name.includes('Impacts'));
        default:
          return filteredByView;
      }
    },
    processedSeries() {
      return this.filteredSeries.map(series => {
        const newSeries = { ...series };
        
        // If viewing only cumulative or only daily, use column type
        if (this.selectedView === 'daily' || this.selectedView === 'cumulative') {
          newSeries.type = 'column';
          
          // If viewing cumulative, change the color to match its daily counterpart
          if (this.selectedView === 'cumulative') {
            if (series.name.includes('Plays')) {
              newSeries.color = '#4ecdc4e0'; // Daily Plays color
            } else if (series.name.includes('Impacts')) {
              newSeries.color = '#c7f464e0'; // Daily Impacts color
            }
          }
        } else {
          // In combined view, keep original types and colors
          if (series.name.includes('Cumulative')) {
            newSeries.type = 'area';
          } else {
            newSeries.type = 'column';
          }
        }
        
        return newSeries;
      });
      },
    mainChartOptions() {
      const formatNumber = (num, isSelectionLabel) => {
        if (num === null || num === undefined) return '0';
        if (isSelectionLabel) {
          return Math.round(num).toLocaleString();
        } else {
          if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
          } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
          } else {
            return num.toString();
          }
        }
      };
  
      const formatTooltipDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
      };
  
      // Determine y-axis configuration based on selected view
      let yAxisConfig = [];
      const yAxisBase = {
        labels: {
          formatter: function (value) {
            return formatNumber(value, false);
          }
        }
      };
  
      if (this.selectedView === 'daily') {
        yAxisConfig = [{
          ...yAxisBase,
          title: { text: "Daily" }
        }];
      } else if (this.selectedView === 'cumulative') {
        yAxisConfig = [{
          ...yAxisBase,
          title: { text: "Cumulative" }
        }];
      } else {
        yAxisConfig = [
          {
            ...yAxisBase,
            title: { text: "Daily" }
          },
          {
            ...yAxisBase,
            opposite: true,
            title: { text: "Cumulative" }
          }
        ];
      }
  
      let res = {
        chart: {
          type: 'line',
          background: 'var(--v-sidebarColorLight-darken2)',
          toolbar: {
            show: false,
            
          },
        },
        theme: {
          mode: 'dark',
        },
        dataLabels: {
          enabled: false,
        },
        labels: this.masterData.categories,
        xaxis: {
          type: 'datetime'
        },
        yaxis: yAxisConfig,
        tooltip: {
          x: {
            formatter: function (value) {
              return formatTooltipDate(value);
            }
          },
          y: {
            formatter: function (value) {
              return formatNumber(value, true);
            }
          }
        }
      };
  
      return res;
    }
  }
  }
  </script>
  