const state = {
    user: {
        isAuthed: false,
    },
};

const getters = {
    user: state => {
        return state.user;
    },
    isAuthed: state => {
        return state.user.isAuthed;
    },
    token: state => {
        return state.user.token;
    }
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_IS_AUTHED(state, boolean) {
        state.user.isAuthed = boolean;
    },
    SET_TOKEN(state, token) {
        state.user.token = token;
    },
};

const actions = {
    async signIn({ commit }, user) {
        if(user.token) {
            commit("SET_USER", user);
            commit("SET_IS_AUTHED", true);
        } 
    },
    async signOut({ commit }) {
        commit("SET_USER", { isAuthed: false }); // Set minimal initial state
        commit("SET_IS_AUTHED", false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}