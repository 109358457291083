var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mb-4",attrs:{"large":""}},[_vm._v("mdi-brush")]),_c('span',{staticClass:"text-h2 ml-2"},[_vm._v("Creative Flighting Builder")]),_c('v-row',{staticClass:"align-center ml-n1 my-1"},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-office-building")]),_vm._l((_vm.selectedMO),function(mo,index){return _c('span',{key:index,staticClass:"text-h3 ml-2"},[_vm._v(_vm._s(mo ? mo.friendlyName : 'Media Owner')+" "+_vm._s(_vm.selectedMO.length > 1 ? ' - ' : ''))])})],2)],1),_c('v-col',{staticClass:"d-flex ml-auto mr-1",staticStyle:{"gap":"0.5em"},attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g({staticStyle:{"transition":"0.3s"},attrs:{"color":hover ? 'primary' : _vm.$vuetify.theme.dark ? '#272727' : '#272727',"fab":"","small":""},on:{"click":function($event){return _vm.generatePublicLink()}}},on),[_c('v-icon',[_vm._v("mdi-earth")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Copy Public Link")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g({staticStyle:{"transition":"0.3s"},attrs:{"color":hover || _vm.showFilters ? 'primary' : _vm.$vuetify.theme.dark ? '#272727' : '#272727',"fab":"","small":""},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Toggle Filters")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g({staticStyle:{"transition":"0.3s"},attrs:{"color":hover ? 'primary' : _vm.$vuetify.theme.dark ? '#272727' : '#272727',"fab":"","small":""},on:{"click":function($event){return _vm.generateExcel()}}},on),[_c('v-icon',[_vm._v("mdi-database-export")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Export Excel")])])],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.formats},scopedSlots:_vm._u([{key:"item.fallback",fn:function(ref){
var item = ref.item;
return [(item.fallback.media.mimeType.includes('video/'))?_c('video',{staticClass:"rounded mx-auto my-4 mt-8 elevation-3",staticStyle:{"object-fit":"cover"},attrs:{"src":item.fallback.media.url,"height":_vm.getCreativeSize(item.fallback).height,"width":_vm.getCreativeSize(item.fallback).width,"controls":""}}):_c('v-img',{staticClass:"rounded mx-auto my-4 mt-8 elevation-3",style:('max-width:'+_vm.getCreativeSize(item.fallback).width+'px; max-height:'+_vm.getCreativeSize(item.fallback).height+'px'),attrs:{"src":item.fallback.media.urlThumb,"height":_vm.getCreativeSize(item.fallback).height,"width":_vm.getCreativeSize(item.fallback).width}})]}},{key:"item.creatives",fn:function(ref){
var item = ref.item;
return [_c('DataItem',{attrs:{"item":item}})]}},{key:"item.medias",fn:function(ref){
var item = ref.item;
return _vm._l((item.medias),function(media,index){return _c('div',{key:index},[_c('v-img',{staticClass:"rounded mx-auto my-4 mt-8 elevation-3",style:('max-width:'+_vm.getCreativeSize(media).width+'px; max-height:'+_vm.getCreativeSize(media).height+'px'),attrs:{"src":media.urlThumb,"height":_vm.getCreativeSize(media).height,"width":_vm.getCreativeSize(media).width}})],1)})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }