<template>
  <apexchart type="bar" :options="groupTypeChartOptions" :series="filteredSeries" height="100%" width="100%">
  </apexchart>
  </template>
  
  <script>
  import apexchart from 'vue-apexcharts';
  
  export default {
  name: 'PlayoutGroupTypeChart',
  components: {
    apexchart,
  },
  props: {
    groupTypeData: {
      type: Object,
      required: true,
    },
    selectedMetric: {
      type: String,
      required: false,
      default: 'both'
    }
  },
  computed: {
    filteredSeries() {
      if (!this.groupTypeData.series) return [];
  
      switch (this.selectedMetric) {
        case 'plays':
          return this.groupTypeData.series.filter(series => series.name.includes('Plays'));
        case 'impacts':
          return this.groupTypeData.series.filter(series => series.name.includes('Impacts'));
        default:
          return this.groupTypeData.series;
      }
    },
    groupTypeChartOptions() {
      const formatNumber = (num, isSelectionLabel) => {
        if (num === null || num === undefined) return '0';
        if (isSelectionLabel) {
          return Math.round(num).toLocaleString();
        } else {
          if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
          } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
          } else {
            return num.toString();
          }
        }
      };
      return {
        chart: {
          type: 'bar',
          background: 'var(--v-sidebarColorLight-darken2)',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },
          zoom: {
            enabled: false
          },
        },
        theme: {
          mode: 'dark',
        },
        plotOptions: {
          bar: {
            horizontal: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.groupTypeData.categories,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatNumber(value, false);
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return formatNumber(value, true);
            }
          }
        },
        fill: {
          opacity: 1
        }
      };
    },
  },
  methods: {
  },
  };
  </script>
  