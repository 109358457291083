<template>
    <div>
        <v-card style="height: 100%">
            <!-- Burst title -->
            <v-row class="ma-0" :style="{ backgroundColor: ($vuetify.theme.dark ? '#363636' : '#d6d6d6') }">
                <v-col cols="auto">
                    <h3>
                        {{ burst.name.charAt(0).toUpperCase() + burst.name.slice(1) }} - {{ burst.deliveryType.name }}

                        <span v-if="burst.startDate" class="ml-4" style="font-size: 0.65em;" :style="{ fontWeight: `300`, fontSize: `12px` }">
                            {{ burst.startDate | moment }} - {{ burst.endDate | moment }}
                        </span>
                    </h3>
                </v-col>
                <v-col cols="auto" style="margin-top: 5px;">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                                    <v-chip style="margin-top:-5px">
                                        <span
                                            class="
                                            d-inline-block 
                                            text-truncate
                                            "
                                            style="
                                            max-width: 400px;
                                            font-size: 0.9rem;
                                            "
                                            v-bind="attrs" v-on="on"
                                        >Latest: {{ burst.workflowStatus ? burst.workflowStatus.as  : 'Milestones pending'}}
                                        </span>
                                    </v-chip>
                                </template>
                        <span>{{ burst.workflowStatus ? burst.workflowStatus.as  : 'Milestones pending' }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>

            <!-- Burst content -->
            <v-card-text>
                <v-row>
                    <v-col cols="auto" class="d-flex" :style="{ position: `relative` }">
                        <!-- Search Screens -->
                        <div>
                            <v-text-field 
                                v-model="searchScreens" 
                                outlined 
                                label="Search" 
                                dense 
                                style="max-width: 430px;"
                                append-icon="mdi-magnify"
                                clearable
                                hide-details
                                @click:clear="searchScreens = ''"
                            ></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center" v-if="routeRequest.formula">
                        <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip small class="height-100" color="darkgrey" label v-on="on">
                        v{{ routeRequest.routeVersion }}
                      </v-chip>
                    </template>
                    <span>Route Version</span>
                  </v-tooltip>
                </v-col>
                <v-col class="pl-2 text-truncate" v-if="routeRequest.formula">
                  <div class="title-text text-truncate" style="white-space: normal !important;">
                    {{ routeRequest.description ? routeRequest.description : routeRequest.formula }}
                  </div>
                  <div class="formula-container text-truncate">
                    <DemographicLexer :value="routeRequest.formula" display-only hideMessages class="compact-lexer" />
                  </div>
                    </v-col>
                </v-row>
                <!-- Screens -->
                <div v-if="mapped">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }" >
                            <v-hover v-slot="{ hover }" >
                                <!-- Sub Issue of an epic display-->
                                <div 
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="toggleExpandAll()"
                                    class="text-right"
                                >
                                    <v-icon 
                                        :color="hover || (expanded.length === showCurrScreenList.length) 
                                            ? 'primary' 
                                            : 'white'"
                                    >
                                        mdi-arrow-expand</v-icon>
                                </div>
                            </v-hover>
                        </template>
                        <span>Expand/Close</span>
                    </v-tooltip>
                    <v-data-table 
                        v-model="selected"
                        :headers="impactsHeaders" 
                        :items="showCurrScreenList" 
                        :hide-default-footer="true"
                        :expanded.sync="expanded" 
                        item-class="ma-0 pa-0" 
                        show-expand
                        show-select
                        :items-per-page="-1" 
                    >
                        <!-- Selected -->
                        <template v-slot:item.isSelected="{ item }">
                            <div style="width: 5px">
                                <v-icon @click="selectTargetRow(item)" :color="item.targetStatusAuto">
                                    {{ (item.targetStatusAuto === 'green') ? 'mdi-checkbox-marked-circle-outline' :
                                        'mdi-checkbox-blank-circle-outline' }}
                                </v-icon>
                            </div>
                        </template>

                        <!-- Auto Target -->
                        <template v-slot:item.route.target="{ item }">
                            <div>
                                <span :class="item.targetStatusAuto + '--text'">{{ addCommas(item.route.target) }}</span>
                            </div>
                        </template>

                        <!-- Expanded Info -->
                        <template v-slot:expanded-item="{ headers, item }" >
                            <!-- we must remove padding, margins and min-height from td -->
                            <td
                                :colspan="headers.length"
                                :class="{ 'ma-0 pa-0': true, 'expanded-closing': !transitioned[getItemId(item)] }"
                                style="height: auto;">
                                <v-expand-transition elevation="0">
                                    <!-- Container we'll transition -->
                                    <div v-show="transitioned[getItemId(item)]">
                                        <!-- container for content. replace with whatever you want -->
                                        <div class="pa-2" style="min-height: 50px;">
                                            <v-row class='justify-center' no-gutters>
                                                <!-- Slot Length -->
                                                <v-col 
                                                    cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 1 5%;' : ''"
                                                    tile outlined
                                                >
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Slot Length</label>
                                                        <label class="d-flex justify-center text-center" :style="{ color: (item.slotDurationSeconds === 0) ? 'grey' : 'white'}">{{ slotLength(item) }}s</label>
                                                    </div>
                                                </v-col>
                                                <!-- Slot Break Length -->
                                                <v-col 
                                                    cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined
                                                >
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Slot Break Length</label>
                                                        <label class="d-flex justify-center text-center" :style="{ color: (item.slotBreakSeconds === -1) ? 'grey' : 'white'}">{{ slotBreakLength(item) }}s</label>
                                                    </div>
                                                </v-col>
                                                <!-- SOT -->
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">SOT %</label>
                                                        <label class="d-flex justify-center text-center"
                                                            contenteditable
                                                            @blur="updateContent($event, item)" 
                                                            style="
                                                                width:80%;
                                                                margin: 0 auto;
                                                            "
                                                        >
                                                            {{ calcSOT(item) }}
                                                        </label>
                                                    </div>
                                                </v-col>
                                                <!-- Frame Count -->
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Frame Count</label>
                                                        <label class="d-flex justify-center text-center">
                                                            <!-- click action does not append Locations-->
                                                            <a @click.stop="
                                                                $router.push({
                                                                    name: 'Artwork',
                                                                    query: {
                                                                        sid: item.screen.id,
                                                                        cbsid: item.id,
                                                                        cid: $route.query.cid,
                                                                        bid: burst.id,
                                                                        tab: 'locations'
                                                                    },
                                                                });
                                                            " style="text-decoration: none"
                                                                class="mouse-pointer">{{ item.locationCount - 1 }}</a>
                                                        </label>
                                                    </div>
                                                </v-col>
                                                <!-- Live Dates -->
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Start Date</label>
                                                        <label class="d-flex justify-center text-center">
                                                            {{ getCampaignStartDate(item) }}
                                                        </label>
                                                    </div>
                                                </v-col>
                                                <v-col  cols="6"
                                                    sm="3"
                                                    :style="$vuetify.breakpoint.mdAndUp ? ' flex: 1 0 5%;' : ''"
                                                    tile outlined>
                                                    <div elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">End Date</label>
                                                        <label class="d-flex justify-center text-center">
                                                            {{ getCampaignEndDate(item) }}
                                                        </label>
                                                    </div>
                                                </v-col>

                                                <!-- Campaign Times -->
                                                <v-col cols="12" tile outlined v-if="item.screenEvents.length > 0">
                                                    <v-card elevation="0">
                                                        <label class="d-flex justify-center font-weight-bold">Campaign Slot Times</label>
                                                        <div class="text-center flex-column">
                                                            <v-row class="flex-column" no-gutters>
                                                                <v-col cols="12">
                                                                    <v-data-table
                                                                    :headers="screenSchedulingEditHeaders"
                                                                    :items="item.screenEvents"
                                                                    :hide-default-footer="true"

                                                                    >
                                                                    <!--
                                                                    <template v-slot:item.actions="{ item }">
                                                                        <v-btn
                                                                        class="m-2"
                                                                        icon
                                                                        small
                                                                        color="primary"
                                                                        @click="deleteExistingScheduledEventFromScreen(item)"
                                                                        >
                                                                        <v-icon> mdi-delete </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    -->
                                                                    </v-data-table>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </v-expand-transition>
                            </td>
                        </template>
                        <template v-slot:item.data-table-expand="props">
                            <v-icon :class="{
                                'v-data-table__expand-icon': true,
                                'v-data-table__expand-icon--active': props.isExpanded && transitioned[getItemId(props.item)]
                            }" @click="toggleExpand(props)">
                                mdi-chevron-down
                            </v-icon>
                        </template>

                    </v-data-table>

                    <div class="mt-10" v-show="chartDataType == 'auto'">
                        <v-btn :loading="submittingNewTargets" :disabled="!showUpdateTargetsBtn"
                            @click="updateTargets()">Update Auto Targets</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
// Controllers
// lazy load
import RouteController from '@/services/controllers/Route'
import CampaignController from '@/services/controllers/Campaign'

// Search Mixins
import collectionSearch from '@/mixins/collection/search'
import roundNumber from '@/mixins/helpers/roundNumber'

// Date formatter
import moment from 'moment'

// LODASH
import cloneDeep from 'lodash/cloneDeep';

import DemographicLexer from '@/components/_theme/demographicLexer/DemographicLexer.vue'

export default {
    mixins: [roundNumber],
    
    components: {
        DemographicLexer
    },

    props: {
        campaign: {
            type: Object,
            required: true,
        },

        burst: {
            type: Object,
            required: true,
        },

        chartMetric: {
            type: String,
            required: true,
        },

        chartDataType: {
            type: String,
            required: true,
        },

        chartMediaOwners: {
            type: Array,
            required: true,
        },

        chartScreens: {
            type: Array,
            required: true,
        },
        data: {
            type: Object,
            required: true
        },
        kpiList: {
            type: Array,
            required: true
        },
        kpiListObjects: {
            type: Object,
            required: true
        },
        arbSync: {
            type: Number,
            default: 0,
            required: true
        },
        type: {
            type: String,
            default: 'report'
        },
        routeRequest: {
            type: Object,
            required: true
        },
        getRouteRequestLabel: {
            type: Function,
            required: true
        }
    },

    filters: {
        moment: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
    },

    data() {
        return {
            targetWorker: null,
            routeVersions: [],
            mapped: false,
            submittingNewTargets: false,
            updatingScreenList: false,
            searchScreens: '',
            originalScreenList: null,
            updatedScreenList: null,
            selected: [],
            dashboardTargets: {},
            changingRouteVersionId: 0,
            expanded: [],
            transitioned: [],
            closeTimeouts: {},
            RepeatIntervals: ['Specific Days', 'Daily'],
            screenSchedulingEditHeaders: [
            {
                text: 'Event type',
                align: 'start',
                sortable: true,
                value: 'repeatInterval',
            },
            {
                text: 'Day Of Week',
                align: 'start',
                sortable: true,
                value: 'dayOfWeek',
            },
            {
                text: 'Start Time',
                align: 'start',
                sortable: true,
                value: 'startTime',
            },
            {
                text: 'End Time',
                align: 'start',
                sortable: true,
                value: 'endTime',
            }
            /*,
            {
                text: 'Actions',
                align: 'start',
                sortable: false,
                value: 'actions',
            },*/
            ],
        }
    },
    watch: {
        expanded (oldVal, newVal) {
            // if newVal is present in oldVal, then close it, if not then open it (and set the transitioned)
            newVal.forEach((item,id) => {
                if (oldVal.indexOf(item.id) === -1) {
                    this.closeExpand(item)
                }
            })
            oldVal.forEach((item,id)  => {
                if (newVal.indexOf(item.id) === -1) {
                    clearTimeout(this.closeTimeouts[item.id])
                    this.$nextTick(() => this.$set(this.transitioned, item.id, true))
                }
            })
        },
        arbSync: async function(val, oldVal) {
            {
                let tab = this.$store.getters.getCampaignTab
                //if(val != oldVal && tab == this.type)
                {
                    await this.mapStats()
                }
            }
        },
        chartMetric: async function(val, oldVal) {
            {
                if(!this.data.mediaOwners) return
                if(val != oldVal)
                {
                    await this.mapStats()
                }
            }
        }
    },

    computed: {
        showCurrScreenList: {
            get() {
                if (this.updatedScreenList && this.updatedScreenList.length > 0 && !this.updatingScreenList) {
                    let collection = collectionSearch(this.updatedScreenList, 'all', this.searchScreens)

                    // Filter by formats / screens
                    if (this.chartScreens.length > 0) {
                        collection = []

                        this.chartScreens.forEach(screenID => {
                            this.updatedScreenList.forEach(item => {
                                if (screenID === item.screen.id)
                                    collection.push(item)
                            })
                        })
                    }

                    // Filter by media owners
                    else if (this.chartMediaOwners.length > 0) {
                        collection = []

                        this.chartMediaOwners.forEach(mediaOwnerID => {
                            this.updatedScreenList.forEach(item => {
                                if (mediaOwnerID === item.screen.mediaOwner.id)
                                    collection.push(item)
                            })
                        })
                    }
                    return collection
                }
                return []
            }
        },

        impactsHeaders: {
            get() {
                let result = []

                //  Add first 3 columns (default)
                result.push({ text: '', align: 'start', sortable: false, value: 'isSelected' })
                result.push({ text: 'Media Owner', align: 'start', sortable: true, value: 'screen.mediaOwner.name' })
                result.push({ text: 'Name', align: 'start', sortable: false, value: 'screen.name' })

                // Add columns based on chartMetric
                

                if (this.getKpiShortNameFromName(this.chartMetric) === 'plays') {
                    if (this.chartDataType.toLowerCase() === 'auto') {
                        result.push({ text: 'Plays Auto Target', align: 'start', sortable: false, value: 'route.target' })
                    }
                }

                else if (this.getKpiShortNameFromName(this.chartMetric) === 'hours') {
                    if (this.chartDataType.toLowerCase() === 'auto') {
                        result.push({ text: 'Hours Auto Target', align: 'start', sortable: false, value: 'route.target' })
                    }
                }
                else { // impacts, ice, reach, cover, frequency
                    if (this.chartDataType.toLowerCase() === 'auto') {
                        // Add Auto and Manual target results 
                        result.push({ text: this.chartMetric + ' Auto Target', align: 'start', sortable: false, value: 'route.target' })
                    }
                }
                result.push({ text: '', value: 'data-table-expand' })
                return result
            }
        },

        showUpdateTargetsBtn: {
            get() {
                let result = false
                this.showCurrScreenList.forEach(item => {
                    if (item.targetStatusAuto === 'amber')
                        result = true
                })

                return result
            }
        },
        calcSOT() {
            return (item) => {
                let sot = 0
                let slotBreakSeconds = item.slotBreakSeconds === -1 ? item.screen.slotBreakSeconds : item.slotBreakSeconds
                let slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
                sot = (slotDurationSeconds / (slotDurationSeconds + slotBreakSeconds)) * 100
                return Math.round(sot * 100) / 100
            }
        },
        //
        slotLength() {
            return (item) => {
                let slotLength = 0
                let slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
                let sot = this.calcSOT(item)
                let slotBreakLength = (((100 / sot) * slotDurationSeconds) - slotDurationSeconds)
                slotLength = (-slotBreakLength * sot) / (sot - 100)
                // check if slotLength is a number
                if (isNaN(slotLength)) {
                    let slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
                    return slotDurationSeconds
                }
                return Math.round(slotLength * 100 / 100)
            }
        },
        reverseCalcSOT() {
            return (sot, duration) => {
                let slotbreak = (100 * duration - sot * duration) / sot
                return slotbreak
            }
        },
        // Get slot break length from sot
        slotBreakLength() {
            return (item) => {
                let slotBreakLength = 0
                let slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
                let sot = this.calcSOT(item)
                slotBreakLength = (((100 / sot) * slotDurationSeconds) - slotDurationSeconds)
                return Math.round(slotBreakLength * 100 / 100)
            }
        },
        getCampaignStartDate() {
            return (item) => {
                let campaignBurstScreenId = item.route.campaignBurstScreen.id
                let campaignBurstScreen = this.burst.campaignBurstScreens.find(cbs => cbs.id === campaignBurstScreenId)
                let startDate = campaignBurstScreen.startDate ?? this.burst.startDate ?? this.campaign.startDate;
                startDate = startDate ? moment(startDate).format('DD MMM YYYY') : 'Not Set'
                return startDate
            }
        },
        getCampaignEndDate() {
            return (item) => {
                let campaignBurstScreenId = item.route.campaignBurstScreen.id
                let campaignBurstScreen = this.burst.campaignBurstScreens.find(cbs => cbs.id === campaignBurstScreenId)
                let endDate = campaignBurstScreen.endDate ?? this.burst.endDate ?? this.campaign.endDate;
                endDate = endDate ? moment(endDate).format('DD MMM YYYY') : 'Not Set'
                return endDate
            }
        },
        // Campaign slot events
        getCampaignEventType() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.repeatInterval
            }
        },
        getCampaignEventDate() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.dayOfWeek
            }
        },
        getCampaignSlotStartTime() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.startTime
            }
        },
        getCampaignSlotEndTime() {
            return (item) => {
                let screenEvents = JSON.parse(JSON.stringify(item.screenEvents))
                return screenEvents.endTime
            }
        },
    },

    async created() {
        let rvers = await RouteController.getRouteVersions()
        this.routeVersions.push(...rvers.data)
    },

    async mounted() {
        // Clone prop so we can change a local copy before saving
        let cleanedCampaignBurstScreens = this.burst.campaignBurstScreens.map(cbs => {
            delete cbs.screen.mediaOwner.screen // delete circular reference
            return cbs
        })
        this.updatedScreenList = cleanedCampaignBurstScreens
        this.originalScreenList = cloneDeep(cleanedCampaignBurstScreens)
        this.changingRouteVersionId = this.burst.routeVersionId

        let tab = this.$store.getters.getCampaignTab || this.$route.query.tab
        if(tab != this.type) return
        if(!this.mapped)
            await this.mapStats()
    },

    beforeDestroy() {
        if(this.targetWorker)
            this.targetWorker.terminate();
        // garbage collection
        this.updatedScreenList = null
        this.originalScreenList = null
        this.dashboardTargets = null
    },

    methods: {
        addCommas(number) {
          const num = parseFloat(number).toFixed(2);
          // Convert the number to a string
          const numberString = num.toString();
          // Split the number into integer and decimal parts
          const [integerPart, decimalPart] = numberString.split('.');
          // Add commas to the integer part
          const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

          // Combine the integer part with the decimal part (if any)
          const result = decimalPart !== undefined ? numberWithCommas + '.' + decimalPart : numberWithCommas;
          // Return the result
          
          return result;
      },

        async mapStats() {
            if(!this.targetWorker)
            {
                this.targetWorker = new Worker('/scripts/workers/TargetWorker.js');
                this.targetWorker.onmessage = (event) => {
                    this.updatedScreenList = event.data.updatedScreenList;
                    this.originalScreenList = event.data.originalScreenList;
                    this.mapped = true;
                    // Component is finished mounting and can be shown on screen now
                    this.$root.$emit('loadNextBurst',"targets")
                };
            }
            if(!this.data.mediaOwners) return
            let data = {
                chartMetric: this.chartMetric,
                dashboardTargets: this.data,
                kpiListObjects: this.kpiListObjects,
                originalScreenList: this.originalScreenList,
                updatedScreenList: this.updatedScreenList,
            }
            this.targetWorker.postMessage(data)
        },

        getKpiIdFromName(kpiName) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
            if(o) return o.id
        },

        getKpiIdFromShortName(shortName) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.shortName === shortName)
            if(o) return o.id
        },

        getKpiShortNameFromName(kpiName) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
            if(o) return o.shortName
        },

        getDesignationIdFromShortName(shortName) {
            let o = this.kpiListObjects.DESIGNATIONs.find(designation => designation.shortName === shortName)
            if(o) return o.id
        },

        getModeIdFromShortName(shortName) {
            let o = this.kpiListObjects.MODEs.find(mode => mode.shortName === shortName)
            if(o) return o.id
        },

        getKpiShortNameFromId(id) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.id === id)
            if(o) return o.shortName
        },

        expandAll() {
        this.updatedScreenList.forEach(item => {
            const id = this.getItemId(item)
            if (item.workflowStatus) {
            this.expanded.push(item)
            this.$set(this.transitioned, id, true)
            }
        })
        },

        toggleExpandAll() {
            // Add every screen to expanded list or remove every screen from expanded list
            if (this.expanded.length === 0) {
                this.updatedScreenList.forEach((item, index) => {
                    // use toggleExpand to add to expanded list
                    this.expanded.push(item)
                    this.$nextTick(() => this.$set(this.transitioned, item.id, true))
                })
            }
            else {
                this.expanded = []
                this.transitioned = []
            }
        },
        async deleteExistingScheduledEventFromScreen (item, screenEvents) {
            const index = screenEvents.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item))

            screenEvents.splice(index, 1)

            await CampaignController.editBurstScreen(burstObj)
            .then((res) => {
                this.$root.$emit('snackbarSuccess', 'Successfully removed scheduled event from screen.')
            })
            .catch((err) => {
                this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        },
        async updateContent(e, item) {
            const inputText = parseFloat(e.target.innerHTML)
            if (!item || !inputText) return
            const slotDurationSeconds = item.slotDurationSeconds === 0 ? item.screen.slotDurationSeconds : item.slotDurationSeconds
            item.slotBreakSeconds = this.reverseCalcSOT(inputText, slotDurationSeconds)
            // update campaignBurstScree obj
            let burstObj = {
                id: item.id,
                slotBreakSeconds: Math.round(item.slotBreakSeconds), // TODO: remove Math.floor() when backend is fixed
                slotDurationSeconds: item.slotDurationSeconds,
                startDate: item.startDate,
                endDate: item.endDate,
                triggerList: item.triggerList
            }
            await CampaignController.editBurstScreen(burstObj).then((res) => {
              item = res.data
              this.$nextTick()
              //this.selectedTriggers = res.data.triggerList
              this.$root.$emit('snackbarSuccess', 'Successfully updated SOT')
            })
            .catch((err) => {
              this.$root.$emit('snackbarError',''+ err.response.data.message)
            })
            
        },
        getItemId(item) {
            return item.id // Must be uid of record (would be nice if v-data-table exposed a "getItemKey" method)
        },
        toggleExpand(props) {
            const item = props.item
            const id = this.getItemId(item)
            //if (!item.workflowStatus) return // Don't expand if there's no workflow status

            if (props.isExpanded && this.transitioned[id]) {
                this.closeExpand(item)
            } else {
                clearTimeout(this.closeTimeouts[id])
                props.expand(true)
                this.$nextTick(() => this.$set(this.transitioned, id, true))
            }
        },
        closeExpand(item) {
            const id = this.getItemId(item)
            // Mark that this item is in the process of closing
            this.$set(this.transitioned, id, false)
            // Remove expansion content from DOM after transition animation has had enough time to finish
            //this.closeTimeouts[id] = setTimeout(() => this.$refs.vDataTable.expand(item, false), 600)
        },

        // auto
        async updateTargets() {
            const modeId = this.getModeIdFromShortName('auto');
            const kpiShortname = this.getKpiShortNameFromName(this.chartMetric); // Cache this value
            this.submittingNewTargets = true
            const collectData = []
            for (let index = 0; index < this.updatedScreenList.length; index++) {
                const item = this.updatedScreenList[index]
                if (item.targetStatusAuto === 'amber'){
                    let gatherData = await this.updateRouteImpacts(item, 'auto', kpiShortname).catch(error => { })
                    if (gatherData)
                        collectData.push(gatherData)
                }
            }
            collectData.forEach((gatherData) => {
                this.$root.$emit('reloadStatData', gatherData, this.burst.id, modeId)
            })
            
            this.submittingNewTargets = false
        },

        async updateRouteImpacts(screen, mode, type) {
            // Screen to change
            const updatedScreen = this.updatedScreenList.find((item) => item.id === screen.id)
            const originalScreen = this.originalScreenList.find((item) => item.id === screen.id)
            if (JSON.stringify(originalScreen) !== JSON.stringify(updatedScreen)) {
                // Map into object to send to the api
                const newScreen = {
                    campaignBurstScreenId: updatedScreen.id,
                    target: updatedScreen.route.target,
                    actual: updatedScreen.route.actual,
                    mode: mode,
                    type: type,
                    routeRequestId: this.routeRequest.id
                }

                // Capture the response from the api
                const { data } = await RouteController.updateDashboardTargets(newScreen).catch(err => {
                    this.$root.$emit('snackbarError',''+ err.response.data.message)
                })

                // Update current with new data from the response
                if (data) {
                    this.updatingScreenList = true

                    this.updatedScreenList.forEach((elem, index) => {
                        if (elem.id == data.campaignBurstScreen.id) {
                            const kpiId = this.getKpiIdFromShortName(type)
                            const modeId = this.getModeIdFromShortName(mode)
                            const designationIdTarget = this.getDesignationIdFromShortName("target")
                            const designationIdActual = this.getDesignationIdFromShortName("actual")
                            this.updateTargetRow(data, index, kpiId, modeId, designationIdTarget, designationIdActual)
                        }
                    })

                    this.updatingScreenList = false
                    return data;
                }
            }
            return null
        },

        // there is a replica of this function in the worker
        async updateTargetRow(data, index, kpiId, modeId, designationIdTarget, designationIdActual)
        {
            let route = { ...data }

            //  Initial color
            let stats = data.dashboardStatsGrouped
            let targetValue = stats.find(stat => stat.kId === kpiId && stat.mId == modeId && stat.dId == designationIdTarget).v
            let actualValue = stats.find(stat => stat.kId === kpiId && stat.mId == modeId && stat.dId == designationIdActual).v // actual is needed for the endpoint

            route.target = targetValue
            route.actual = actualValue

            let color = (Number(targetValue) > 0) ? "green" : "red";

            // Combine updates to reduce redundant operations
            const updatedData = {
                route,
                targetStatusAuto: color,
                isSelected: false
            };

            // Update both updatedScreenList and originalScreenList in one go
            this.$set(this.updatedScreenList, index, { ...this.updatedScreenList[index], ...updatedData });
            this.$set(this.originalScreenList, index, { ...this.originalScreenList[index], ...updatedData });

            
        },

        selectTargetRow(item) {
            this.updatingScreenList = true

            if(this.selected.length > 0 && this.selected.find(selectedItem => selectedItem.id === item.id)){
                this.selected.forEach((selectedItem, index) => {
                // Update selected row status
                selectedItem.isSelected = !selectedItem.isSelected

                const selectedCampaignBurstScreenId = selectedItem.route.campaignBurstScreen.id;
                // Update route data
                selectedItem.route = this.originalScreenList.find(item => item.route.campaignBurstScreen.id === selectedCampaignBurstScreenId).route;

                //  Update select box color
                if (!selectedItem.isSelected && selectedItem.route.target > 0)
                    selectedItem.targetStatusAuto = 'green'

                else if (selectedItem.isSelected)
                    selectedItem.targetStatusAuto = 'amber'

                else
                    selectedItem.targetStatusAuto = 'red'
                });
            }
            else{
                this.updatedScreenList.forEach((elem, index) => {
                if (elem.id === item.id) {
                    // Update selected row status
                    this.updatedScreenList[index].isSelected = !this.updatedScreenList[index].isSelected

                    // Update route data
                    this.updatedScreenList[index].route = JSON.parse(JSON.stringify(this.originalScreenList[index].route))

                    //  Update select box color
                    if (!this.updatedScreenList[index].isSelected && this.updatedScreenList[index].route.target > 0)
                        this.updatedScreenList[index].targetStatusAuto = 'green'

                    else if (this.updatedScreenList[index].isSelected)
                        this.updatedScreenList[index].targetStatusAuto = 'amber'

                    else
                        this.updatedScreenList[index].targetStatusAuto = 'red'
                }
            })
            }

            this.updatingScreenList = false
        },
    }
}
</script>
<style scoped>
.expanded-closing {
    border-bottom: none !important;
}
</style>

<style scoped>
.compact-content {
width: 100%;
overflow: hidden;
}

.title-text {
  font-size: 1rem;
font-weight: 500;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
line-height: 20px;
}

.formula-container {
margin-top: -2px;
filter: grayscale(0.3);
font-size: 0.875rem;
color: rgba(255, 255, 255, 0.7);
}

.height-100 {
height: 100% !important;
width: 48px;
}

::v-deep .v-list-item {
padding: 4px 16px !important;
}

/* Add this new class */
::v-deep .v-select__selections {
max-height: 40px;
overflow: hidden;
}

/* Add this to ensure the row stays within bounds */
.v-row.no-gutters {
max-width: 100%;
margin: 0;
}

</style>