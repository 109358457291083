<template>
    <v-dialog v-model="showCreateCFSS" width="650">
        <v-card>
            <v-card-title style="background-color: #4AA6D5;">
                <h4 class="headline my-1">Generate CFS - {{ campaign.name }}</h4>
            </v-card-title>
            <v-card-text class="d-flex flex-column">
                <v-row class="mt-2"> 
                    <v-col cols="6">
                        <v-combobox
                            v-model="selectedMO"
                            :items="mediaOwners"
                            multiple
                            item-text="name"
                            item-value="id"
                            label="Media Owner"
                            return-object
                            outlined
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-combobox
                            v-model="selectedBurst"
                            multiple
                            :items="bursts"
                            item-text="name"
                            item-value="id"
                            label="Burst"
                            return-object
                            outlined
                            required
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-n2">
                    <v-col cols="6">
                        <v-combobox
                            :disabled="selectedBurst === null"
                            v-model="selectedScreens"
                            :items="screens"
                            :item-text="item => item.screen.name + ' - ' + item.screen.mediaOwner.friendlyName + ' - ' + item.burstName "
                            item-value="id"
                            label="Screens"
                            multiple
                            return-object
                            outlined
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-combobox
                            v-model="selectedFields"
                            :items="fields"
                            item-text="name"
                            item-value="id"
                            label="Fields to include"
                            multiple
                            return-object
                            outlined
                            required
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col
                    cols="12"
                    class="text-center pa-0 ma-0 mb-3 mt-n3"
                >
                    <v-btn
                        @click="confirmCreateCFS()"
                        color="primary"
                        class="mr-3"
                    >
                        Generate
                    </v-btn>
                    <v-btn
                        color="red"
                        @click="$emit('closeDialog')"
                    >
                        Cancel
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        showCreateCFS: {
            type: Boolean,
            required: true,
        },
        campaign: {
            type: Object,
            required: true,
        },
        bursts: {
            type: Array,
            required: true,
        },
        mediaOwners: {
            type: Array,
            required: true,
        },
    },
    data: () => {
        return {
            // MO
            selectedMO: [],
            // Burst
            selectedBurst: [],
            // Screens
            selectedScreens: [],
            // Fields
            selectedFields: [],
        }
    },
    computed: {
        // Compute showCreateCFS from props to get showCreateCFS value and set $emit('closeDialog')
        showCreateCFSS: {
            get() {
                return this.showCreateCFS
            },
            set() {
                this.$emit('closeDialog')
            }
        },
        screens() {
            if(this.selectedBurst.length > 0){
                // Return an array of screens of the selectedBurst
                let screens = []
                this.selectedBurst.forEach(burst => {
                    //for eeach cbs, add .burstName = burst.name
                    burst.campaignBurstScreens.forEach(cbs => {
                        cbs.burstName = burst.name
                    })
                    screens = screens.concat(burst.campaignBurstScreens)
                })
                return screens
            }
            return []
        },
        fields() {
            return [
                {id: 1, name: 'Dates'},
                {id: 2, name: 'Part of Day (Time)'},
                {id: 3, name: 'Location (Region)'},
                {id: 4, name: 'Schare of Voice'},
            ]
        },
        screensId(){
            // Return a string of array of screens id divided by ,
            return this.selectedScreens.map(item => item.id).join(',')
        },
        moIds(){
            // Return a string of array of media owners id divided by ,
            return this.selectedMO.map(item => item.id).join(',')
        },
        burstIds(){
            // Return a string of array of bursts id divided by ,
            return this.selectedBurst.map(item => item.id).join(',')
        },
    },
    methods: {
        confirmCreateCFS() {
            // Redirect to CFS page with query paramas { cid: this.campaign.id, bid: this.selectedBurst.id, sid: this.screensId  } })
            this.$router.push({ 
                name: 'CFS', 
                query: { 
                    cid: this.campaign.id, 
                    bid: this.burstIds, 
                    sid: this.screensId,
                    mo: this.moIds,
                    fields: this.selectedFields.map(item => item.id).join(',')
                } })
            this.$emit('closeDialog')
        }
    }
}
</script>